
import { defineComponent, ref } from "vue";
import AddressCard from "@/components/common/AddressCard.vue";
import axios from "@/api/axios";
import qs from "qs";
import Toast from "@/components/UI/Toast";
export default defineComponent({
  name: "Address",
  components: {
    AddressCard,
  },
  setup() {

    const list = ref<object>([]);

    

    function getList() {
      axios.get("/M/User/Address").then(res => {
          console.log(res)
          list.value = res.data.obj.list;
      }).catch(err => {
          console.log(err)
      })
    }

    getList()

    function handleDel(id: string) {
      const data = qs.stringify({
        id
      });
      axios.post("/M/Server/DoDelAddress", data).then(res => {
          console.log(res)
          Toast({
            type: "success",
            title: "删除成功！",
           });
          getList()
      }).catch(err => {
          console.log(err)
      })
    }
    
    return {
      list,
      handleDel
    }
  },
});
