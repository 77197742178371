
import { defineComponent, watch, nextTick } from "vue";
import useAddress from "@/hooks/useAddress";
export default defineComponent({
  name: "addressCard",
  emit: ['handle-del', 'handle-edit'],
  props: {
    list: {
      type: Array,
      default: [] as object[]
    },
  },
  setup(props, ctx) {
    
    const refs: Array<HTMLElement> = [];
    
    const addressCard = (el: HTMLElement) => {
        refs.push(el);
    }

    watch(() => props.list, ()=>{
        if(props.list.length > 0){
            nextTick(() => {
                props.list.map((item, index) => {
                    // useAddress(refs[index])
                })
            })
        }
    })

    function delAddress(id: string){
      ctx.emit("handle-del", id);
    }

    function editAddress(id: string) {
      ctx.emit("handle-edit", id);
    }
    
    return {
      addressCard,
      delAddress,
      editAddress
    };
  },
});
